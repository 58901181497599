import React, { Fragment, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  Divider,
  Avatar,
  colors
} from '@material-ui/core';
import {
  Search as SearchIcon,
  XCircle as XIcon,
  Check as CheckIcon
} from 'react-feather';
import axios from 'src/utils/axios';
import { toast } from 'react-toastify'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 500,
    maxWidth: '100%',
    top: 68
  },
  bubble: {
    borderRadius: theme.shape.borderRadius
  },
  avatar: {
    //border: `2px solid ${theme.palette.text.secondary}`,
    margin: "auto",
    background: "transparent",
    height: 75,
    width: 75,
    color: theme.palette.text.secondary
  },
  avatarGreen: {
    border: `2px solid ${colors.green[600]}`,
    margin: "auto",
    background: "transparent",
    height: 75,
    width: 75,
    color: colors.green[600]
  },
}));

const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.primary.main,
    animationDuration: '950ms',
    position: 'absolute',
    left: -196,
    top: -36
  },
  circle: {
    strokeLinecap: 'round',
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={70}
        thickness={1}
        {...props}
      />
    </div>
  );
}

function Search(props) {
  const classes = useStyles();
  const { lastTransactionStatus } = props;

  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTransaction(null);
    setTransactionStatus(null);
    setOpen(false);
  };

  useEffect(() => {
    if (lastTransactionStatus && isOpen) {
      setTransaction(lastTransactionStatus);
      if (lastTransactionStatus.indexOf("TEMP") == 0) setTransactionStatus("pending");
      else setTransactionStatus("finished");
    }
  }, [lastTransactionStatus]);

  const handleContribution = () => {
    var success = props.onContribute();
    if (success) setTransactionStatus("pending");
    else handleClose();
  }

  const pendingTransaction = (
    <>
      <Box padding={3} >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h5"
            color="textPrimary"
          >
            Create transaction
    </Typography>

          <IconButton onClick={handleClose}>
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box p={3}>
        <Box
          flexGrow={1}
          p={8}
          bgcolor="background.dark"
          className={classes.bubble}
        >
          <Box
            display="flex"
            alignItems="center"
            mb={3}
          >
            <Avatar className={classes.avatar}>
              <CheckIcon />
            </Avatar>
            <FacebookCircularProgress />
          </Box>
          <Typography
            variant="body2"
            color="secondary"
            style={{ textAlign: "center" }}
          >
            Waiting for signature...
    </Typography>
        </Box>
        <Box
          flexGrow={1}
          p={2}
          mt={4}
          bgcolor="background.dark"
          className={classes.bubble}
        >
          <Box
            display="flex"
            alignItems="center"
            mb={1}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Action
      </Typography>
            <Box flexGrow={1} />
          </Box>
          <Typography
            variant="body2"
            color="secondary"
          >
            Open Metamask to sign your transaction.
    </Typography>
        </Box>
      </Box>
    </>
  )

  const completedTransaction =
    (
      <>
        <Box padding={3} >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h5"
              color="textPrimary"
            >
              Create transaction
      </Typography>

            <IconButton onClick={handleClose}>
              <SvgIcon fontSize="small">
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box p={3}>
          <Box
            flexGrow={1}
            p={8}
            bgcolor="background.dark"
            className={classes.bubble}
          >
            <Box
              display="flex"
              alignItems="center"
              mb={3}
            >
              <Avatar className={classes.avatarGreen}>
                <CheckIcon />
              </Avatar>
            </Box>
            <Typography
              variant="body2"
              color="secondary"
              style={{ textAlign: "center", color: colors.green[600] }}
            >
              Success! Your transaction has been sent to the network for processing.
      </Typography>
          </Box>
          <Box
            flexGrow={1}
            p={2}
            mt={4}
            bgcolor="background.dark"
            className={classes.bubble}
          >
            <Box
              display="flex"
              alignItems="center"
              mb={1}
            >
              <Typography
                color="textPrimary"
                variant="h6"
              >
                Action
        </Typography>
              <Box flexGrow={1} />
            </Box>
            <Box >
              <Typography
                variant="body2"
                color="secondary"
              >
                You can check your transaction status with Etherscan:
            </Typography>
              <Button
                variant="text"
                style={{ color: colors.green[600] }}
                component="a"
                href={`https://ropsten.etherscan.io/tx/${transaction}`}
                target="_blank"
                size="small"
              >
                Transaction status
                  </Button>
            </Box>

          </Box>
        </Box>
      </>
    )

  const transactionContent =
    <>
      <Box padding={3} >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h5"
            color="textPrimary"
          >
            New contribution
      </Typography>

          <IconButton onClick={handleClose}>
            <SvgIcon fontSize="small">
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Box>
      </Box>
      <Divider />
      <Box p={3}>
        <Box
          flexGrow={1}
          p={2}
          bgcolor="background.dark"
          className={classes.bubble}
        >
          <Box
            display="flex"
            alignItems="center"
            mb={1}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              Action
        </Typography>
            <Box flexGrow={1} />
          </Box>
          <Typography
            variant="body2"
            color="textPrimary"
          >
            This action will send a contribution to the Metha Crowdsale for the selected period(s).
      </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
          >
            Once the period(s) will be finished you will be able to claim your received Metha Tokens from Vault.
      </Typography>
        </Box>
        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={handleContribution}
          >
            Create transaction
      </Button>
        </Box>
        <Box mt={4}>
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
              <>
                {results && results.map((result) => (
                  <Box mb={2}>
                    <Link
                      variant="h4"
                      color="textPrimary"
                      component={RouterLink}
                      to="/app"
                    >
                      {result.title}
                    </Link>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                    >
                      {result.description}
                    </Typography>
                  </Box>
                ))}
              </>
            )}
        </Box>
      </Box>
    </>


  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          if (props.contribution < 0.01) return toast.error("The minimum contribution is 0.01 ETH.", { position: toast.POSITION.TOP_RIGHT });
          else handleOpen();
        }}
      >
        Contribute
          </Button>


      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          {transactionStatus ? transactionStatus == "pending" ? pendingTransaction : completedTransaction : transactionContent}
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}

export default Search;
