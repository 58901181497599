import React, { useState, useEffect } from 'react';
import Vault from './Vault';
import Wallet from './Wallet';
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Divider
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClaimButton from './ClaimButton';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  rightDivider: {
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('xs')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  item: {
    padding: theme.spacing(3)
    /*  textAlign: 'center' */
  }
}));

export default props => {
  const {
    className,
    accountClaimableTokens,
    getAccountBalance,
    init,
    accountCurrentPeriodContribution,
    currentPeriod,
    onAddTokenToMetamask,
    ...rest
  } = props;
  const classes = useStyles();
  const { onClaimTokens, lastTransactionStatus } = props;
  const [accountMethaBalance, setAccountMethaBalance] = useState(0);

  const pending = Number(accountCurrentPeriodContribution) > 0;

  useEffect(() => {
    getAccountBalance().then(data => {
      setAccountMethaBalance(data);
    });
  }, []);

  useEffect(() => {
    getAccountBalance().then(data => {
      setAccountMethaBalance(data);
    });
  }, [accountClaimableTokens, init]);

  const claimButton = (
    <ClaimButton
      onClaim={onClaimTokens}
      lastTransactionStatus={lastTransactionStatus}
    />
  );

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Your balance" />
      <Divider />
      <CardContent style={{ padding: 8 }}>
        <Grid container justify="space-between" spacing={2}>
          <Grid
            item
            md={6}
            sm={6}
            xs={12}
            className={`${classes.rightDivider} ${classes.item}`}
          >
            <Vault
              claimButton={claimButton}
              value={accountClaimableTokens}
              currentPeriod={currentPeriod}
              pending={pending}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12} className={classes.item}>
            <Wallet
              value={accountMethaBalance}
              onAddTokenToMetamask={onAddTokenToMetamask}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
