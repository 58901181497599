import { API, Auth } from 'aws-amplify';
import amplify from 'src/amplify.json';

class APIUtils {
  /*   getUserData() {
          return new Promise((resolve, reject) => {
              API.post(amplify.API.endpoints[0].name, "/comprehend/analyze", {
                  body: { debiToken, fileNameToAnalyze }
              })
                  .then(result => resolve(result))
                  .catch(err => reject(err))
          });
      } */

  getDappData() {
    return new Promise((resolve, reject) => {
      API.get(amplify.API.endpoints[0].name, '/dapp/fetchData', {
        queryStringParameters: {}
      })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  getTokenPrice() {
    return new Promise((resolve, reject) => {
      API.get(amplify.API.endpoints[0].name, '/dapp/getTokenPrice', {
        queryStringParameters: {}
      })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  getTransactions(pagination, lastPeriod, lastIndex) {
    return new Promise((resolve, reject) => {
      API.get(amplify.API.endpoints[0].name, '/dapp/fetchTransactions', {
        queryStringParameters: {
          pagination,
          lastPeriod,
          lastIndex
        }
      })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  async getUserData() {
    const headers = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    };

    return new Promise((resolve, reject) => {
      API.get(amplify.API.endpoints[0].name, '/user/get', {
        headers,
        queryStringParameters: {}
      })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }

  async updateUserWallet(wallet) {
    const headers = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    };
    return new Promise((resolve, reject) => {
      API.post(amplify.API.endpoints[0].name, '/user/updatewallet', {
        headers,
        body: { wallet }
      })
        .then(result => resolve(result))
        .catch(err => reject(err));
    });
  }

  async userCheckContribution() {
    const headers = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`
    };
    return new Promise((resolve, reject) => {
      API.post(amplify.API.endpoints[0].name, '/user/checkcontribution', {
        headers
      })
        .then(result => resolve(result))
        .catch(err => reject(err));
    });
  }
}

const apiUtils = new APIUtils();
export default apiUtils;
