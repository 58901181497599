import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Divider,
  FormControlLabel,
  FormHelperText,
  Switch,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { Trash as TrashIcon } from 'react-feather';
import wait from 'src/utils/wait';

const useStyles = makeStyles(theme => ({
  root: {},
  confirmButton: {
    marginLeft: theme.spacing(2)
  }
}));

function AddEditEventForm({ event, mode, onAdd, onCancel, onDelete, onEdit }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={{
        allDay: false,
        color: '',
        description: '',
        end: moment(),
        start: moment(),
        title: ''
      }}
      validationSchema={Yup.object().shape({
        allDay: Yup.bool(),
        description: Yup.string().max(5000),
        end: Yup.date().when(
          'start',
          (start, schema) =>
            start && schema.min(start, 'End date must be later than start date')
        ),
        start: Yup.date(),
        title: Yup.string()
          .max(255)
          .required('Title is required')
      })}
      onSubmit={async (
        values,
        { resetForm, setErrors, setStatus, setSubmitting }
      ) => {
        try {
          const data = {
            ...values
          };

          // Make API request depending on mode type
          // If editting an event, here should make a patch request
          // else a post request
          await wait(200);

          // On post request server should return an ID
          if (mode === 'add') {
            data.id = uuidv4();
          } else {
            data.id = event.id;
          }

          resetForm();
          setStatus({ success: true });
          setSubmitting(false);
          enqueueSnackbar('Saved changes', {
            variant: 'success'
          });

          if (mode === 'add') {
            onAdd(data);
          } else {
            onEdit(data);
          }
        } catch (error) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Box p={3}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
              color="textPrimary"
            >
              Edit Period
            </Typography>
          </Box>
          <Box p={3}>
            <Box p={1}>
              <Typography variant="h4">
                Currently you can only contribute to "Current Period".
              </Typography>
            </Box>
            <Box p={1}>
              <Typography variant="h5" color="primary">
                More advanced contribution functionalities will be available
                soon!
              </Typography>
            </Box>
          </Box>
          {/*  <Box p={3}>
              <TextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label="Title"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                variant="outlined"
              />
              <Box mt={2}>
                <TextField
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  label="Description"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                />
              </Box>
              <Box mt={2}>
                <FormControlLabel
                  control={(
                    <Switch
                      checked={values.allDay}
                      name="allDay"
                      onChange={handleChange}
                    />
                  )}
                  label="All day"
                />
              </Box>
              <Box mt={2}>
                <DateTimePicker
                  fullWidth
                  inputVariant="outlined"
                  label="Start date"
                  name="start"
                  onClick={() => setFieldTouched('end')}
                  onChange={(date) => setFieldValue('start', date)}
                  value={values.start}
                />
              </Box>
              <Box mt={2}>
                <DateTimePicker
                  fullWidth
                  inputVariant="outlined"
                  label="End date"
                  name="end"
                  onClick={() => setFieldTouched('end')}
                  onChange={(date) => setFieldValue('end', date)}
                  value={values.end}
                />
              </Box>
              {Boolean(touched.end && errors.end) && (
                <Box mt={2}>
                  <FormHelperText error>
                    {errors.end}
                  </FormHelperText>
                </Box>
              )}
            </Box>
             */}

          <Divider />
          <Box p={2} display="flex" alignItems="center">
            {mode === 'edit' && (
              <IconButton onClick={() => onDelete(event.id)}>
                <SvgIcon>
                  <TrashIcon />
                </SvgIcon>
              </IconButton>
            )}
            <Box flexGrow={1} />
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              color="secondary"
              className={classes.confirmButton}
              onClick={onCancel}
            >
              Confirm
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}

AddEditEventForm.propTypes = {
  onCancel: PropTypes.func
};

AddEditEventForm.defaultProps = {
  onCancel: () => {}
};

export default AddEditEventForm;
