import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, colors, Box } from '@material-ui/core';
import { withContext } from 'src/context';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
//import Alert from 'components/Alert';

import Label from 'src/components/Label';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main
  },
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    width: '33%',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  overline: {
    marginTop: theme.spacing(1)
  },
  iFrame: {
    border: 'unset',
    width: '100%',
    height: '100%'
  },
  popUp: {
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      height: '80%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '100%'
    }
  },
  close: {
    position: 'absolute',
    right: 10,
    margin: 20
  },
  wrapper: {
    width: theme.breakpoints.values.lg
  },
  alert: {
    marginTop: theme.spacing(0),
    color: '#ffffff',
    backgroundColor: 'white'
  },
  button: {
    marginLeft: theme.spacing(3),
    color: theme.palette.secondary.contrastText
  },
  intense: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main
  },
  avatar: {
    backgroundColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.main,
    height: 48,
    width: 48
  }
}));

const Statistics = props => {
  const { className, popUp, ...rest } = props;
  const classes = useStyles();

  const iframe = (
    <div
      style={{ width: '100%', height: '100%', position: 'relative' }}
      className={classes.wrapper}
    >
      <IconButton
        onClick={() => popUp.closePopup()}
        size="small"
        className={classes.close}
        style={{
          position: 'absolute',
          right: 10,
          margin: 20,
          color: '#546E7A'
        }}
      >
        <CloseIcon />
      </IconButton>
      <div style={{ width: '100%', height: window.innerHeight * 0.9 }}>
        <iframe
          style={{ width: '100%', height: '100%', border: 'unset' }}
          src="https://josevictormadrid90.typeform.com/to/Z3sNGH"
        ></iframe>
      </div>
    </div>
  );

  const open = () => {
    popUp.openPopup(iframe);
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <Box>
        <Box style={{ display: 'flex' }}>
          <Typography>
            Complete the survey and earn free Metha Tokens on launch!
          </Typography>
          <Button
            className={classes.button}
            size="small"
            variant="outlined"
            onClick={open}
          >
            Survey
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

Statistics.propTypes = {
  className: PropTypes.string
};

export default withContext(Statistics);
