const Web3 = require('web3');
const moment = require('moment');
const config = require('src/dapp-config.json');

var web3 = new Web3(config.dappEndpoint);

//Contracts
const MethaCrowdsale = require('../contracts/MethaCrowdsale.json');
const MethaToken = require('../contracts/MethaToken.json');

//Instances
const MethaCrowdsaleInstance = new web3.eth.Contract(
  MethaCrowdsale.abi,
  config.MethaCrowdsaleAdress
);

const MethaTokenInstance = new web3.eth.Contract(
  MethaToken.abi,
  config.MethaTokenAddress
);

const weiToEth = (number, decimals) => {
  if (isNaN(number)) return 0;
  return (
    Math.floor(number / Math.pow(10, 18 - decimals)) / Math.pow(10, decimals)
  );
};

class DappService {
  getDappInfo = async () => {
    var wei_raised = await getDappValue(
      MethaCrowdsaleInstance,
      'get_wei_raised'
    );
    var periodDistribution = await getDappValue(
      MethaCrowdsaleInstance,
      'get_tokens_created_per_period'
    );
    var currentPeriod = await getDappValue(
      MethaCrowdsaleInstance,
      'get_current_period'
    );
    var remaining_period_time = await getDappValue(
      MethaCrowdsaleInstance,
      'get_remaining_period_time'
    );
    var start_time_of_next_period = moment()
      .add(remaining_period_time, 'second')
      .toDate();
    var wei_raised_current_period = await getDappValue(
      MethaCrowdsaleInstance,
      'get_wei_raised_per_period',
      [0]
    );
    var wei_raised_other_period = await getDappValue(
      MethaCrowdsaleInstance,
      'get_wei_raised_per_period',
      [1]
    );
    var get_tokensale_stats = await getDappValue(
      MethaCrowdsaleInstance,
      'get_tokensale_stats'
    );
    var tokenSaleStats = Object.keys(get_tokensale_stats).map(e =>
      weiToEth(get_tokensale_stats[e], 2)
    );
    const periodRaised = isNaN(wei_raised_current_period)
      ? 0
      : weiToEth(wei_raised_current_period, 6);
    //var test = await getDappValue(MethaCrowdsaleInstance, "contributors_data_per_period");

    return {
      wei_raised,
      currentPeriod,
      start_time_of_next_period,
      tokenSaleStats,
      periodRaised,
      remainingPeriodTime: remaining_period_time,
      periodDistribution
    };
  };

  getTransactions = async (pagination, lastPeriod = -1, lastIndex = -1) => {
    //var current_period = await getDappValue(MethaCrowdsaleInstance, "getCurrentPeriod");
    var current_period = await getDappValue(
      MethaCrowdsaleInstance,
      'getNextPeriodWithContributions',
      [3000]
    );
    var transactions = [];

    for (var i = lastPeriod >= 0 ? lastPeriod : current_period; i >= 1; ) {
      var length = await getDappValue(
        MethaCrowdsaleInstance,
        'getContributorsLengthPerPeriod',
        [i]
      );
      length = isNaN(Number(length)) ? 0 : Number(length);

      for (
        var j = lastIndex >= 0 && i == lastPeriod ? lastIndex - 1 : length - 1;
        j >= 0;
        j--
      ) {
        var data = await getDappValue(
          MethaCrowdsaleInstance,
          'getContributorsPeriodIndex',
          [i, j]
        );
        if (transactions.length == pagination - 1) {
          transactions.push(formatTransaction(data, i));

          return {
            period: i,
            index: j,
            transactions
          };
        } else {
          transactions.push(formatTransaction(data, i));
        }
      }
      var next = await getDappValue(
        MethaCrowdsaleInstance,
        'getNextPeriodWithContributions',
        [i]
      );

      if (next == 0) {
        return {
          period: 0,
          index: 0,
          transactions
        };
      } else {
        i = next;
      }
    }
    return {
      period: 0,
      index: 0,
      transactions
    };
  };

  getTokenPrice = async () => await getMethaPriceAuction(10);

  test = async () => {
    //    var tokenPrice = await getMethaPriceAuction(7);
  };
}

const getMethaPriceAuction = async n => {
  var dates = [];
  var values = [];

  var firstPeriodWithContributions = await getDappValue(
    MethaCrowdsaleInstance,
    'getNextPeriodWithContributionsFromCurrent'
  );

  //Static values
  var periodDuration = await getDappValue(
    MethaCrowdsaleInstance,
    'getPeriodDuration'
  );

  var tokensCreatedPerPeriod = await getDappValue(
    MethaCrowdsaleInstance,
    'getTokensCreatedPerPeriod'
  );

  var startTimeFirstPeriod = await getDappValue(
    MethaCrowdsaleInstance,
    'getStartTimeOfFirstPeriod'
  );

  var lastPeriod = firstPeriodWithContributions;

  for (var i = 0; i < n; i++) {
    if (lastPeriod == '0') break;
    var date = getPeriodDate(startTimeFirstPeriod, periodDuration, lastPeriod);
    var raised = await getDappValue(
      MethaCrowdsaleInstance,
      'getWeiRaisedPerPeriod',
      [lastPeriod]
    );

    var price = weiToEth(Number(raised), 2) / Number(tokensCreatedPerPeriod);

    dates.push(date.format('DD MMM HH[h]'));
    values.push(price);

    lastPeriod = await getDappValue(
      MethaCrowdsaleInstance,
      'getNextPeriodWithContributions',
      [lastPeriod]
    );
  }

  return {
    dates: dates.reverse(),
    values: values.reverse()
  };
};

const getPeriodDate = (start, periodDuration, periodNumber) => {
  var startDate = new Date(start * 1000);
  return moment(startDate).add(periodDuration * Number(periodNumber), 'second');
};

const formatTransaction = (data, period) => {
  return {
    address: data[0],
    contribution: weiToEth(Number(data[2]), 2),
    token_balance: weiToEth(Number(data[1]), 2),
    time: new Date(Number(data[3]) * 1000),
    period
  };
};

const getDappValue = (contract, methodName, args = []) => {
  return new Promise((resolve, reject) => {
    contract.methods[methodName](...args)
      .call()
      .then(result => resolve(result))
      .catch(err => reject(err));
  });
};

export default new DappService();
