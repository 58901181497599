import React, { Fragment, useState, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';
import { Provider } from 'src/context.js';
import Amplify, { Auth, Hub } from 'aws-amplify';
import amplify from 'src/amplify.json';
import CustomPopup from 'src/components/CustomPopup';
import apiUtils from 'src/resources/api-utils';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { throttle } from 'lodash';
import DrizzleContext from 'src/DrizzleContext';
import { withProvider } from 'src/withDrizzle';
import DappService from 'src/services/dapp.service';
Amplify.configure(amplify);

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

var popUpActions;

function App() {
  useStyles();

  const { settings } = useSettings();

  const [state, _setState] = useState({
    user: null,
    userData: {},
    customState: null,
    conversion_rates: {
      usd: 0.000041 * 223.34,
      eth: 0.000041,
      btc: 0.000001025
    },
    test: 1,
    withDrizzle: false
  });

  const setState = newState => {
    var _newState = {
      ...state,
      ...newState
    };
    _setState(_newState);
  };

  const [methaBalance, setMethaBalance] = useState(0);
  const [showMetamaskHelp, setShowMetamaskHelp] = useState(false);

  const [dappState, setDappState] = useState({
    wei_raised: 0,
    current_period: 0,
    remaining_period_time: 0,
    start_time_of_next_period: new Date(),
    tokenSaleStats: [0, 0, 1],
    periodRaised: 0
  });

  const [tokenPrice, setTokenPrice] = useState({ dates: [], values: [] });

  useEffect(() => {
    // Listen for auth events
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          if (state.authState != 'signedIn') {
            getUserData(data);
          }
          break;
        case 'signOut':
          if (state.authState != 'signIn')
            setState({ user: null, authState: 'signIn' });
          break; /* 
        case "customOAuthState":
          setState({ customState: data }); */
      }
    });

    // Check the current user when the App component is loaded
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (state.authState != 'signedIn') {
          getUserData(user);
        }
      })
      .catch(e => {
        if (state.authState != 'signIn')
          setState({ authState: 'signIn', user: null });
      });

    handleRefreshData();
    let id = setInterval(delayedRefresh.current, 30000);
    return () => clearInterval(id);
  }, []);

  const getUserData = async data => {
    setState({ authState: 'signedIn' });

    try {
      await apiUtils.userCheckContribution();
    } catch (e) {
      console.log(e);
    }

    try {
      var userData = await apiUtils.getUserData();
      setState({ user: data, authState: 'signedIn', userData });
    } catch (e) {
      console.log(e);
    }
  };

  const signOut = () => {
    Auth.signOut()
      .then(() => {
        setState({ authState: 'signIn', user: null, userData: null });
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleUpdateUserWallet = async wallet => {
    var response = await apiUtils.updateUserWallet(wallet);
    if (!response.status)
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    else {
      var newUserData = await apiUtils.getUserData();
      setState({ userData: newUserData });
    }
  };

  // Change user attributes
  /*   let user = await Auth.currentAuthenticatedUser();
   
  let result = await Auth.updateUserAttributes(user, {
      'email': 'me@anotherdomain.com',
      'family_name': 'Lastname'
  });
  console.log(result); // SUCCESS */

  const handleRefreshData = async () => {
    var data = await apiUtils.getDappData();
    setDappState({ ...data });
    //Get token price
    apiUtils.getTokenPrice().then(setTokenPrice);
  };

  const delayedRefresh = React.useRef(
    throttle(q => handleRefreshData(q), 5000)
  );

  const getContext = () => {
    return {
      ...state,
      dappState: dappState,
      tokenPrice,
      methaBalance,
      showMetamaskHelp,
      onRefreshData: delayedRefresh.current,
      setShowMetamaskHelp,
      reRender: () => setState({ test: state.test + 1 }),
      onSignOut: signOut,
      popUp: popUpActions,
      onUpdateUserWallet: handleUpdateUserWallet,
      onToggleDrizzle: () => setState({ withDrizzle: !state.withDrizzle }),
      onSetGlobalMethaBalance: setMethaBalance,
      onAddToContext: (element, name) =>
        _setState({ ...state, [name]: element })
    };
  };

  return (
    <Provider value={getContext()}>
      <ThemeProvider theme={createTheme(settings)}>
        <ToastContainer style={{ marginTop: 60 }} />
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={1}>
              <Router history={history}>
                <ScrollReset />
                <GoogleAnalytics />
                <CookiesNotification />
                <SettingsNotification />
                <Routes />
              </Router>
              <CustomPopup
                onRef={ref => {
                  popUpActions = ref;
                }}
              />
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
