import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  colors,
  Chip,
  Grid,
  Box,
  Tooltip
} from '@material-ui/core';
import uuid from 'uuid/v1';
import moment from 'moment';
import { withContext } from 'src/context';
import { useSelector } from 'react-redux';

import { Chart } from './components';

const round = input => {
  return Math.floor(input * 100) / 100;
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'unset',
    height: '100%'
  },
  content: {
    padding: 0,
    /* flexDirection: "column", */
    '&:last-child': {
      paddingBottom: 0
    }
  },
  statsContainer: {
    /* display: 'flex', */
    width: '40%',
    textAlign: 'left',
    margin: '0 auto',
    paddingTop: theme.spacing(1)
  },
  statsItem: {
    margin: 'auto',
    flexDirection: 'column',
    /* justifyContent: 'center', */
    padding: theme.spacing(1),
    maxWidth: 300
  },
  cardHeader: {
    height: '15%'
  },
  paddingTwo: {
    padding: theme.spacing(0, 3)
  },
  chartHeight: {
    height: 220
  },
  userItems: {
    //backgroundColor: theme.palette.background.dark,
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.secondary.main}`,
    position: 'relative'
  },
  total: {
    borderTop: `2px solid ${theme.palette.divider}`
  }
}));

const EarningsSegmentation = props => {
  const {
    className,
    contribution,
    accountCurrentPeriodContribution,
    periodRaised,
    userData,
    ...rest
  } = props;

  const classes = useStyles();

  const { connectionData } = useSelector(state => state.dapp);

  var others = Number(periodRaised) - Number(accountCurrentPeriodContribution);
  var stacked = Number(accountCurrentPeriodContribution);
  var newContribution = Number(contribution);
  var total = Number(periodRaised) + Number(contribution);

  //  ['#3d72eb', '#4b9e86', '#b658f5']
  var earnings = [
    {
      id: uuid(),
      label: 'CURRENT',
      value: total === 0 ? 0 : round(stacked / total) * 100,
      color: '#3d72eb'
    },
    {
      id: uuid(),
      label: 'NEW',
      value: total === 0 ? 0 : round(newContribution / total) * 100,
      color: '#40c4ff'
    },
    {
      id: uuid(),
      label: 'OTHERS',
      value: total === 0 ? 0 : round(others / total) * 100,
      color: '#4b9e86'
    }
  ];

  if (total === 0)
    earnings = [
      {
        id: uuid(),
        label: '',
        value: 100,
        color: '#3d72eb30'
      }
    ];

  var legend = [
    {
      id: uuid(),
      label: 'NEW',
      value: total === 0 ? 0 : round(newContribution / total) * 100,
      realValue: newContribution,
      color: '#40c4ff'
    },
    {
      id: uuid(),
      label: 'CURRENT',
      value: total === 0 ? 0 : round(stacked / total) * 100,
      realValue: stacked,
      color: '#3d72eb'
    },
    {
      id: uuid(),
      label: 'OTHERS',
      value: total === 0 ? 0 : round(others / total) * 100,
      realValue: others,
      color: '#4b9e86'
    },
    {
      id: uuid(),
      label: 'TOTAL',
      value: 100,
      realValue: total,
      color: '#b658f5'
    }
  ];

  //Remove new if equals 0
  if (!newContribution) legend.shift();

  const renderLegendItem = earning => (
    <div className={classes.statsItem} key={earning.id}>
      <Grid alignItems="center" container justify="center" spacing={1}>
        {/* <Grid item xs={12} sm={12}>
        <Typography
          variant="overline"
          gutterBottom
          style={{ margin: "auto" }}
        >
          {earning.label}:
            </Typography>
      </Grid> */}
        <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
          <Chip
            style={{
              width: '100%',
              maxWidth: 200,
              fontSize: '14px',
              color: earning.color,
              backgroundColor: `${earning.color}20`,
              border: `1px solid ${earning.color}`,
              fontWeight: 600
            }}
            label={`${earning.label}: ${String(earning.realValue).slice(
              0,
              5
            )} ETH - ${String(earning.value).slice(0, 5)}%`}
            size="small"
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader title="Contributions Segmentation" />

      <CardContent /* className={classes.content} */>
        <Grid alignItems="center" container justify="center" spacing={1}>
          <Grid item xs={12} md={7} className={classes.chartHeight}>
            <Chart
              data={earnings}
              style={{
                margin: 'auto',
                pointerEvents: total === 0 ? 'none' : 'all'
              }}
            />
          </Grid>
          {/* <Divider /> */}
          <Grid item xs={12} md={5}>
            <Box className={classes.userItems} p={0}>
              {connectionData.wallet ? (
                <Box p={1}>
                  <Tooltip title={userData.wallet} placement="top">
                    <Typography variant="h6" color="secondary">
                      YOU (
                      {connectionData.wallet.slice(0, 5) +
                        '...' +
                        connectionData.wallet.slice(-5)}
                      )
                    </Typography>
                  </Tooltip>
                </Box>
              ) : (
                <Box p={1}>
                  <Typography variant="h6" color="secondary">
                    YOU (unknown)
                  </Typography>
                </Box>
              )}
              {legend
                .filter(
                  earning =>
                    earning.label == 'CURRENT' || earning.label == 'NEW'
                )
                .map(earning => renderLegendItem(earning))}
            </Box>
            <Box p={0} pt={0}>
              {legend
                .filter(earning => earning.label == 'OTHERS')
                .map(earning => renderLegendItem(earning))}
            </Box>
            <Box className={classes.total}>
              {legend
                .filter(earning => earning.label == 'TOTAL')
                .map(earning => renderLegendItem(earning))}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

EarningsSegmentation.propTypes = {
  className: PropTypes.string
};

export default withContext(EarningsSegmentation);
